import { createTheme } from '@mui/material/styles';

const baseTheme = {
    typography: {
        fontFamily: 'PP Gosha Sans, Arial, sans-serif', // Font principale con fallback
        h1: {
            fontSize: '64px',
            fontWeight: 'bold',
            color: '#34ab6a', // Colore personalizzato
        },
        h2: {
            fontSize: '40px',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '32px',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '1.4rem', // 2xl corrisponde a 1.5rem
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '1rem', // Base size
            fontWeight: 'normal',
        },
        body1: {
            fontSize: '1rem', // Base size
            fontWeight: 'normal',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    display: 'inline-flex',
                    height: '60px',
                    padding: '16px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexShrink: 0,
                    borderRadius: '8px',
                    border: '2px solid #A669F7', // Main border color
                    backgroundColor: '#FFF', // Default background
                    color: '#A669F7', // Default text color
                    fontFamily: '"PP Gosha Sans", Arial, sans-serif', // Custom font
                    fontSize: '21px',
                    fontWeight: 700,
                    lineHeight: '28px', // Line height
                    textAlign: 'center',
                    fontFeatureSettings: "'liga' off, 'clig' off",
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Smooth transitions
                    transform: 'translateY(0)', // Default position

                    '&:hover': {
                        backgroundColor: '#FFF', // Keeps the same background
                        boxShadow: '0px 8px 0px 0px #121212', // Shadow on hover
                        transform: 'translateY(-2px)', // Slight bounce effect
                        color: '#A669F7', // Text color remains the same
                    },
                    '&:active': {
                        backgroundColor: '#A669F7', // Pressed state background
                        borderColor: '#A669F7', // Same as main color
                        color: '#FFF', // Text color changes to white
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        display: 'flex',
                        width: 'inherit', // Usa la larghezza del genitore
                        height: '56px',
                        padding: '14px 57px 14px 19px',
                        alignItems: 'center',
                        flexShrink: 0,
                        borderRadius: '16px',
                        backgroundColor: '#FFF', // White background
                        transition: 'border-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition

                        '& fieldset': {
                            borderWidth: '2px', // Spessore del bordo
                            borderColor: '#EAEAEA', // Default border color
                            transition: 'border-color 0.3s ease', // Smooth transition
                        },
                        '&:hover fieldset': {
                            borderColor: '#C4C4C4', // Hover border color
                            
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#C4C4C4', // Focus border color
                            boxShadow: '0 0 4px rgba(196, 196, 196, 0.5)', // Optional shadow
                        },
                    },
                    '& .MuiInputBase-input': {
                        fontFamily: 'PP Gosha Sans, Arial, sans-serif',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '1.5em',
                        paddingBottom: '8px', // Placeholder alignment
                        color: '#121212', // Text color

                        '&::placeholder': {
                            color: '#121212', // Placeholder color
                            transition: 'color 0.3s ease', // Smooth transition for placeholder
                            fontSize: '16px', // Default font size

                            // Media queries
                            '@media (min-width: 768px) and (max-width: 1023px)': {
                                fontSize: '14px', // Font size per tablet
                            },
                            '@media (max-width: 767px)': {
                                fontSize: '12px', // Font size per mobile
                            },
                        },

                        '&:hover::placeholder': {
                            color: '#121212', // Placeholder color on hover
                        },
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px', // Space between icon and text
                },
                icon: {
                    width: '24px',
                    height: '24px', // Icon dimensions
                },
            },
        },    
    },
};

// Light Theme
const lightTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: 'light',
        primary: {
            main: '#2ABF67', // Action 1
        },
        secondary: {
            main: '#FFC54D', // Action 2
        },
        background: {
            default: '#FFFFFF', // Neutral White
            paper: '#E4F0FF', // Neutral
        },
        text: {
            primary: '#121212', // Black
            secondary: '#6B6B6B', // Dark Grey
            white: '#f6f6f6',
            disabled: '#C4C4C4', // Middle Grey
        },
        action: {
            hover: '#F9F5CC', // Secondary
        },
        divider: '#EAEAEA4D', // Light Grey
    },
});

// Dark Theme
const darkTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: 'dark',
        primary: {
            main: '#2ABF67', // Action 1
        },
        secondary: {
            main: '#FFC54D', // Action 2
        },
        background: {
            default: '#121212', // Black
            paper: '#1E1E1E', // Dark Grey
        },
        text: {
            primary: '#FFFFFF', // White
            secondary: '#AAAAAA', // Light Grey
            disabled: '#555555', // Disabled Grey
        },
        action: {
            hover: '#333333', // Darker Grey for Hover
        },
        divider: '#555555', // Dark Grey Divider
    },
});

export { lightTheme as default, darkTheme }; // Esporta il tema light come predefinito
