import { v4 as uuidv4 } from 'uuid';

// Nome del cookie per salvare l'UUID
const COOKIE_NAME = 'user_uuid';

// Funzione per ottenere o generare un UUID
export const getUserUUID = () => {
    // Controlla se l'UUID è già salvato nei cookie
    const existingUUID = document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${COOKIE_NAME}=`));

    if (existingUUID) {
        // Se il cookie esiste, restituiscilo
        return existingUUID.split('=')[1];
    }

    // Altrimenti genera un nuovo UUID e salva il cookie
    const newUUID = uuidv4();
    document.cookie = `${COOKIE_NAME}=${newUUID}; path=/; max-age=${180 * 24 * 60 * 60}`; // Valido per 180 giorni
    return newUUID;
};
