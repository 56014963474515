import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WIPPagePerstudenti from './WIPPagePerstudenti';




function App() {
  return (

        <Routes>

          {/* Imposta la pagina WIP come homepage */}
          <Route path="/" element={<WIPPagePerstudenti />} />
          {/* Fallback per qualsiasi altra route */}
          <Route path="*" element={<WIPPagePerstudenti />} />



        </Routes>

  );
}

export default App;
