// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from './api/axiosConfig';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({
        userId: null,
        username: '',
        avatar: '',
        token: null,
        isLoggedIn: false,
        hasCompletedOnboarding: false,
        isOnboardingComplete: false
    });

    useEffect(() => {
        // Carica i dati utente da localStorage se disponibili
        const userId = localStorage.getItem('userId');
        const username = localStorage.getItem('username');
        const avatar = localStorage.getItem('avatar');
        const token = localStorage.getItem('token');
        const hasCompletedOnboarding = localStorage.getItem('hasCompletedOnboarding') === 'true';

        if (userId && token) {
            setUser({
                userId,
                username,
                avatar,
                token,
                isLoggedIn: true,
                hasCompletedOnboarding,
                isOnboardingComplete: hasCompletedOnboarding
            });
        }
    }, []);


    const handleLoginSuccess = (userId, username, avatar, token, onboardingCompleted) => {
        setUser({
            userId,
            username,
            avatar,
            token,
            isLoggedIn: true,
            hasCompletedOnboarding: onboardingCompleted,
            isOnboardingComplete: onboardingCompleted // Imposta lo stato di completamento onboarding
        });

        // Salva i dati dell'utente in localStorage se desiderato
        localStorage.setItem('userId', userId);
        localStorage.setItem('username', username);
        localStorage.setItem('avatar', avatar);
        localStorage.setItem('hasCompletedOnboarding', onboardingCompleted ? 'true' : 'false');
        localStorage.setItem('token', token);
    };

    const handleLogout = async () => {
        try {
            await axiosInstance.post('/logout/');
            setUser({
                userId: null,
                username: '',
                avatar: '',
                token: null,
                isLoggedIn: false,
                hasCompletedOnboarding: false,
                isOnboardingComplete: false
            });
            localStorage.clear();
        } catch (error) {
            console.error('Error during logout:', error);
            // Eventualmente, visualizza un messaggio di errore all'utente
        }
    };


    return (
        <UserContext.Provider value={{ user, setUser, handleLoginSuccess, handleLogout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
