import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
    padding: 24px;
    background-color: ${(props) =>
        props.inModal ? 'transparent' : props.theme.palette.background.default};
    color: ${(props) => props.theme.palette.text.primary};
    line-height: 1.6;
    max-height: ${(props) => (props.inModal ? '300px' : 'auto')};
    overflow-y: ${(props) => (props.inModal ? 'auto' : 'visible')};
`;

const Title = styled.h1`
    font-size: ${(props) => props.theme.typography.h1.fontSize};
    color: ${(props) => props.theme.palette.text.primary};
    margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
    font-size: ${(props) => props.theme.typography.h3.fontSize};
    color: ${(props) => props.theme.palette.text.primary};
    margin-top: 24px;
`;

const Paragraph = styled.p`
    margin-bottom: 16px;
`;

const List = styled.ul`
    margin-left: 20px;
    list-style: disc;
`;

const TermsAndConditions = ({ inModal }) => {
    return (
        <PolicyContainer inModal={inModal}>
            <Title>Termini e Condizioni</Title>
            <Paragraph>Ultimo aggiornamento: 9 dicembre 2024</Paragraph>
            <Paragraph>
                Benvenuto su perstudenti.com. Utilizzando questo sito, accetti i seguenti Termini e Condizioni.
                Se non sei d'accordo, ti invitiamo a non utilizzare il sito.
            </Paragraph>

            <SectionTitle>1. Servizio Offerto</SectionTitle>
            <Paragraph>
                perstudenti.com è una piattaforma online che permette agli utenti di pubblicare e cercare
                annunci riguardanti prodotti, servizi o opportunità di studio. Il sito funge esclusivamente da
                tramite per tali annunci senza rivendicare diritti sui contenuti degli stessi.
            </Paragraph>

            <SectionTitle>2. Uso del Sito</SectionTitle>
            <List>
                <li>Gli utenti devono essere maggiorenni o avere il consenso di un genitore/tutore.</li>
                <li>È vietato pubblicare contenuti illeciti, falsi, offensivi o in violazione di leggi vigenti.</li>
                <li>perstudenti.com non garantisce la veridicità o accuratezza degli annunci pubblicati.</li>
            </List>

            <SectionTitle>3. Responsabilità</SectionTitle>
            <List>
                <li>
                    Gli utenti sono interamente responsabili dei contenuti che pubblicano sul sito. Questo
                    include la responsabilità per eventuali dichiarazioni false, violazioni della proprietà intellettuale,
                    o infrazioni alle normative vigenti.
                </li>
                <li>
                    perstudenti.com non è responsabile per danni diretti, indiretti, incidentali, o di altro tipo
                    derivanti dall'uso del sito o dall'affidamento sui contenuti pubblicati dagli utenti.
                </li>
                <li>
                    Il sito si riserva il diritto di rimuovere o modificare contenuti che violano i presenti Termini e
                    Condizioni o che sono considerati inaccettabili a discrezione del gestore del sito.
                </li>
            </List>

            <SectionTitle>4. Proprietà Intellettuale</SectionTitle>
            <Paragraph>
                Tutti i diritti sui contenuti e il design del sito sono riservati. È vietato riprodurre o distribuire il
                materiale senza autorizzazione scritta da perstudenti.com. Gli utenti mantengono i diritti sui loro
                contenuti, ma concedono al sito una licenza limitata per l'esposizione e l'utilizzo dei contenuti
                all'interno della piattaforma.
            </Paragraph>

            <SectionTitle>5. Modifiche</SectionTitle>
            <Paragraph>
                Il sito si riserva il diritto di modificare i presenti Termini e Condizioni e di apportare modifiche
                alla piattaforma. Gli utenti saranno informati delle modifiche tramite comunicazioni appropriate
                sul sito. L'uso continuato del sito dopo l'introduzione delle modifiche implica l'accettazione delle
                stesse.
            </Paragraph>

            <SectionTitle>6. Clausola di Manleva</SectionTitle>
            <Paragraph>
                Gli utenti accettano di manlevare e tenere indenne perstudenti.com da qualsiasi pretesa o
                azione legale, incluse le spese legali, derivanti dai contenuti pubblicati o da condotte che
                violano i presenti Termini e Condizioni.
            </Paragraph>

            <SectionTitle>7. Contatti</SectionTitle>
            <Paragraph>
                Per dubbi o chiarimenti, scrivi a <a href="mailto:info@perstudenti.com">info@perstudenti.com</a>.
            </Paragraph>
        </PolicyContainer>
    );
};

export default TermsAndConditions;
