import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Switch } from '@mui/material';
import axiosInstance from './api/axiosConfig';
import { getUserUUID } from './uuidHelper'; // Importa la funzione per gestire l'UUID

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    pointer-events: ${(props) => (props.show ? 'auto' : 'none')};
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: right; /* Centra i pulsanti */
    align-items: center;
    gap: 16px; /* Spaziatura tra i pulsanti */
    width: 100%; /* Imposta la larghezza massima */
    padding: 8px 16px; /* Aggiungi padding al wrapper */

    @media (max-width: 768px) {
        flex-direction: column; /* Layout verticale sui dispositivi mobili */
        gap: 12px; /* Riduci lo spazio tra i pulsanti */
    }

    button {
        width: auto; /* Consenti dimensionamento automatico */
        min-width: 100px; /* Imposta una larghezza minima */
        max-width: 180px; /* Imposta una larghezza massima */
        text-align: center; /* Centra il testo */
        padding: 8px 16px; /* Padding interno */
        font-size: 14px; /* Riduci la dimensione del font per dispositivi mobili */
    }
`;

const BannerContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    justify-content: space-between; /* Elementi allineati ai lati opposti */
    align-items: center;
    z-index: 1000;

    p {
        margin: 0;
        color: ${(props) => props.theme.palette.text.primary};
        text-align: left; /* Allinea il testo a sinistra su Desktop */
    }

    button {
        margin-left: 16px;
    }

    @media (max-width: 768px) {
        flex-direction: column; /* Cambia la direzione del layout */
        align-items: center; /* Centra gli elementi */
        gap: 16px; /* Spaziatura tra gli elementi */

        p {
            font-size: 14px;
            text-align: center; /* Centra il testo sui dispositivi mobili */
        }

        button {
            margin-left: 0; /* Rimuovi il margine sinistro */
            width: 100%; /* I pulsanti occupano tutta la larghezza */
            max-width: 300px; /* Imposta una larghezza massima */
        }
    }
`;


const CookiePopup = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.palette.background.paper};
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    width: 90%;
    max-width: 400px;

    h4 {
        margin-bottom: 16px;
        text-align: center;
        font-size: ${(props) => props.theme.typography.h5.fontSize};
        color: ${(props) => props.theme.palette.text.primary};
    }

    p {
        font-size: ${(props) => props.theme.typography.body1.fontSize};
        margin-bottom: 16px;
        color: ${(props) => props.theme.palette.text.secondary};
    }

    label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    button {
        margin-top: 16px;
    }

    @media (max-width: 768px) {
        padding: 16px;

        h4 {
            font-size: 16px;
        }

        p {
            font-size: 14px;
        }

        label {
            flex-direction: column;
            align-items: flex-start;

            span {
                font-size: 14px;
            }
        }

        button {
            width: 100%; /* Il pulsante occupa tutta la larghezza */
        }
    }
`;

const CookieBanner = () => {
    const [cookiesSettings, setCookiesSettings] = useState({
        necessary: true,
        analytics: true,
        marketing: true,
    });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const userUUID = getUserUUID(); // Ottieni o genera UUID

    useEffect(() => {
        console.log('User UUID:', userUUID);
    }, [userUUID]);

    // Funzione per salvare il consenso
    const saveCookieConsent = async (settings) => {
        try {
            const response = await axiosInstance.post('/save-consent/', {
                uuid: userUUID,
                cookies_analytics: settings.analytics,
                cookies_marketing: settings.marketing,
            });
            console.log('Consenso salvato con successo:', response.data);
        } catch (error) {
            console.error(
                'Errore durante il salvataggio del consenso:',
                error.response?.data || error.message
            );
        }
    };

    const handleAcceptAll = () => {
        const allCookiesEnabled = {
            necessary: true,
            analytics: true,
            marketing: true,
        };
        setCookiesSettings(allCookiesEnabled);
        saveCookieConsent(allCookiesEnabled);
        setCookiesAccepted(true); // Chiudi il banner
    };

    const handleSaveSettings = () => {
        saveCookieConsent(cookiesSettings);
        setCookiesAccepted(true); // Chiudi il banner
    };

    const handleDecline = () => {
        const declinedSettings = {
            necessary: true,
            analytics: false,
            marketing: false,
        };
        setCookiesSettings(declinedSettings);
        saveCookieConsent(declinedSettings);
        setCookiesAccepted(true); // Chiudi il banner
    };

    return (
        <>
            {!cookiesAccepted && <Overlay show />}
            {!cookiesAccepted && (
                <BannerContainer>
                    <p>Usiamo i cookies per migliorare la tua esperienza. Accetti l'utilizzo?</p>
                    <ButtonWrapper>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAcceptAll}
                        >
                            Accetta Tutti
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsPopupOpen(true)}
                        >
                            Gestisci Cookies
                        </Button>
                        <Button variant="text" color="error" onClick={handleDecline}>
                            Rifiuta
                        </Button>
                    </ButtonWrapper>
                </BannerContainer>
            )}
            {isPopupOpen && (
                <CookiePopup>
                    <h4>Impostazioni Cookie</h4>
                    <p>Puoi configurare quali cookies attivare per la tua esperienza.</p>
                    <label>
                        Analytics
                        <Switch
                            checked={cookiesSettings.analytics}
                            onChange={() =>
                                setCookiesSettings((prev) => ({
                                    ...prev,
                                    analytics: !prev.analytics,
                                }))
                            }
                        />
                    </label>
                    <label>
                        Marketing
                        <Switch
                            checked={cookiesSettings.marketing}
                            onChange={() =>
                                setCookiesSettings((prev) => ({
                                    ...prev,
                                    marketing: !prev.marketing,
                                }))
                            }
                        />
                    </label>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            handleSaveSettings();
                            setIsPopupOpen(false);
                        }}
                    >
                        Salva Impostazioni
                    </Button>
                </CookiePopup>
            )}
        </>
    );
};

export default CookieBanner;
