import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
    padding: 24px;
    background-color: ${(props) => props.theme.palette.background.default};
    color: ${(props) => props.theme.palette.text.primary};
    line-height: 1.6;
`;

const Title = styled.h1`
    font-size: ${(props) => props.theme.typography.h1.fontSize};
    color: ${(props) => props.theme.palette.text.primary};
    margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
    font-size: ${(props) => props.theme.typography.h3.fontSize};
    color: ${(props) => props.theme.palette.text.primary};
    margin-top: 24px;
`;

const Paragraph = styled.p`
    margin-bottom: 16px;
`;

const PrivacyPolicy = ({ inModal }) => {
    return (
        <PolicyContainer>
            {!inModal && <Title>Privacy Policy</Title>}
            <Paragraph>Ultimo aggiornamento: 9 dicembre 2024</Paragraph>
            <Paragraph>
                Questa Privacy Policy descrive come raccogliamo, utilizziamo e proteggiamo i tuoi dati
                personali su perstudenti.com.
            </Paragraph>
            <SectionTitle>1. Titolare del Trattamento</SectionTitle>
            <Paragraph>
                Il titolare del trattamento è il gestore di perstudenti.com, contattabile all’indirizzo email{' '}
                <a href="mailto:info@perstudenti.com">info@perstudenti.com</a>.
            </Paragraph>
            {/* Resto dei contenuti */}
        </PolicyContainer>
    );
};

export default PrivacyPolicy;
