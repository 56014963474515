import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './UserContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'; // ThemeProvider di Material-UI
import { ThemeProvider as StyledThemeProvider } from 'styled-components'; // ThemeProvider di Styled Components
import CssBaseline from '@mui/material/CssBaseline'; // Reset CSS globale di Material-UI
import theme from './Theme'; // Importa il tema personalizzato

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MUIThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}> {/* Aggiunto ThemeProvider per Styled Components */}
        <CssBaseline />
        <Router>
          <UserProvider>
            <App />
          </UserProvider>
        </Router>
      </StyledThemeProvider>
    </MUIThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
