// axiosConfig.js
import axios from 'axios';
import { csrfToken } from '../utils/csrf';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000/api', // Fallback a localhost se non definito
    headers: {
        'X-CSRFToken': csrfToken(),
    },
    withCredentials: true, // Includi i cookie
});

// Aggiungi l'interceptor per aggiungere il token di autenticazione
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Token ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Gestione degli errori di autenticazione
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Token scaduto o non valido
            localStorage.removeItem('token');
            window.location.href = '/login'; // Reindirizza alla pagina di login
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
