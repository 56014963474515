import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
    padding: 24px;
    background-color: ${(props) => props.theme.palette.background.default};
    color: ${(props) => props.theme.palette.text.primary};
    line-height: 1.6;
`;

const Title = styled.h1`
    font-size: ${(props) => props.theme.typography.h1.fontSize};
    color: ${(props) => props.theme.palette.text.primary};
    margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
    font-size: ${(props) => props.theme.typography.h3.fontSize};
    color: ${(props) => props.theme.palette.text.primary};
    margin-top: 24px;
`;

const Paragraph = styled.p`
    margin-bottom: 16px;
`;

const List = styled.ul`
    margin-left: 20px;
    list-style: disc;
`;

const CookiesPolicy = () => {
    return (
        <PolicyContainer>
            <Title>Cookie Policy</Title>
            <Paragraph>Ultimo aggiornamento: 9 dicembre 2024</Paragraph>
            <Paragraph>
                Benvenuto su perstudenti.com. Questa Cookie Policy spiega cosa sono i cookie, come li utilizziamo e quali opzioni hai riguardo al loro utilizzo.
            </Paragraph>

            <SectionTitle>1. Cosa sono i Cookie?</SectionTitle>
            <Paragraph>
                I cookie sono piccoli file di testo che vengono memorizzati sul tuo dispositivo quando visiti un sito web. Servono a migliorare l’esperienza di navigazione, fornire funzionalità aggiuntive e raccogliere informazioni per analisi e pubblicità.
            </Paragraph>

            <SectionTitle>2. Tipologie di Cookie Utilizzate</SectionTitle>
            <List>
                <li><strong>Cookie Tecnici Essenziali:</strong> Necessari per il corretto funzionamento del sito.</li>
                <li><strong>Cookie di Prestazione:</strong> Raccolgono informazioni anonime sull’uso del sito.</li>
                <li><strong>Cookie di Funzionalità:</strong> Permettono al sito di ricordare le tue preferenze.</li>
                <li><strong>Cookie di Profilazione e Marketing:</strong> Utilizzati per mostrarti annunci pubblicitari pertinenti.</li>
                <li><strong>Cookie di Terze Parti:</strong> Servizi come Google Analytics raccolgono statistiche anonime.</li>
            </List>

            <SectionTitle>3. Finalità dell’Uso dei Cookie</SectionTitle>
            <List>
                <li>Garantire il funzionamento tecnico del sito.</li>
                <li>Analizzare e monitorare il comportamento degli utenti.</li>
                <li>Mostrare pubblicità mirata e personalizzata.</li>
            </List>

            <SectionTitle>4. Come Gestire i Cookie</SectionTitle>
            <Paragraph>
                Al tuo primo accesso, ti verrà chiesto di accettare o configurare le preferenze relative ai cookie tramite un banner informativo.
            </Paragraph>
            <Paragraph>Puoi modificare le tue preferenze in qualsiasi momento:</Paragraph>
            <List>
                <li>Tramite il banner di configurazione accessibile in basso alla pagina.</li>
                <li>
                    Tramite le impostazioni del tuo browser:
                    <ul>
                        <li><a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
                        <li><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
                        <li><a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></li>
                        <li><a href="https://support.microsoft.com/it-it/microsoft-edge" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
                    </ul>
                </li>
            </List>

            <SectionTitle>5. Conservazione dei Cookie</SectionTitle>
            <List>
                <li><strong>Cookie di sessione:</strong> Cancellati al termine della navigazione.</li>
                <li><strong>Cookie persistenti:</strong> Rimangono sul dispositivo per un periodo predefinito o finché non vengono cancellati manualmente.</li>
            </List>

            <SectionTitle>6. Cookie di Terze Parti</SectionTitle>
            <Paragraph>
                Utilizziamo servizi di terze parti che potrebbero impostare cookie sul tuo dispositivo, come Google Analytics e Google Ads. Consulta le loro politiche per maggiori informazioni.
            </Paragraph>

            <SectionTitle>7. Aggiornamenti a Questa Cookie Policy</SectionTitle>
            <Paragraph>
                Ci riserviamo il diritto di aggiornare questa Cookie Policy in base a modifiche legislative o ai nostri servizi. Le modifiche saranno comunicate tramite il sito.
            </Paragraph>

            <SectionTitle>8. Contatti</SectionTitle>
            <Paragraph>
                Per qualsiasi domanda o richiesta relativa a questa Cookie Policy, scrivici a <a href="mailto:info@perstudenti.com">info@perstudenti.com</a>.
            </Paragraph>
        </PolicyContainer>
    );
};

export default CookiesPolicy;
