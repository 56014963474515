import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRive, useStateMachineInput } from 'rive-react';
import { useTheme } from '@mui/material/styles';
import axiosInstance from './api/axiosConfig';
import Typography from '@mui/material/Typography';
import Form from './Form';
import Popup from './Popup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CookieBanner from './CookieBanner';
import { getUserUUID } from './uuidHelper'; // Importa la funzione per gestire l'UUID
import CookiesPolicy from './pages/Policies/CookiesPolicy';
import PrivacyPolicy from './pages/Policies/PrivacyPolicy';
import TermsAndConditions from './pages/Policies/TermsAndConditions';
import Modal from './Modal'; // Se Modal è in una directory components


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(
        to bottom,
        ${(props) => props.theme.palette.primary.main} 50%,
        ${(props) => props.theme.palette.background.default} 50%
    );
    gap: 32px;
`;

const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
`;

const BottomSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
    padding-top: 32px;
`;

const AnimationWrapper = styled.div`
    width: 90vw;
    max-width: 1200px;
    height: 16vh;
    display: flex;
    justify-content: center;
    align-items: center;

    canvas {
        width: 100%;
        height: 100%;
    }

    @media (min-width: 768px) {
        width: 80vw;
        height: 24vh;
    }

    @media (min-width: 1024px) {
        width: 60vw;
        height: 24vh;
    }
`;

const TextWrapper = styled.div`
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 768px) {
        text-wrap: balance; /* Bilancia automaticamente il testo */
        -webkit-text-wrap: balance; /* Prefisso per WebKit */
        word-break: break-word; /* Evita parole troppo lunghe */
        font-size: 1.25rem; /* Dimensione del font per dispositivi mobili */
        line-height: 1.5; /* Migliora la leggibilità */
    }
`;
const FormWrapper = styled.div`
    width: 90vw;
    max-width: 1200px;
`;

const Footer = styled.footer`
    margin-top: auto;
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.theme?.palette?.text?.secondary || '#6B6B6B'};
    padding: 16px;

    a {
        color: ${(props) => props.theme?.palette?.primary?.main};
        text-decoration: none;
        margin: 0 8px;

        &:hover {
            text-decoration: underline;
        }
    }
`;


const WIP_Page_Perstudenti = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [popupContent, setPopupContent] = useState(null); // Stato per gestire il contenuto del popup
    const [showCookieBanner, setShowCookieBanner] = useState(true);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false); // Stato per il checkbox
    const [cookiesSettings, setCookiesSettings] = useState({
        necessary: true, // Sempre abilitati
        analytics: false,
        marketing: false,
    });

    const userUUID = getUserUUID(); // Ottieni o genera l'UUID
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);



    // Controlla il consenso dei cookie
    useEffect(() => {
        const fetchConsent = async () => {
            try {
                const response = await axiosInstance.get(`/get-user-consent/?uuid=${userUUID}`);
                console.log('Stato consenso:', response.data);

                // Imposta lo stato dei cookie
                if (response.data.cookies_analytics && response.data.cookies_marketing) {
                    setCookiesAccepted(true);
                } else {
                    setCookiesAccepted(false);
                    setCookiesSettings({
                        analytics: response.data.cookies_analytics || false,
                        marketing: response.data.cookies_marketing || false,
                    });
                }
                setShowCookieBanner(!response.data.cookies_analytics || !response.data.cookies_marketing);
            } catch (error) {
                console.error('Errore durante il recupero del consenso:', error.response?.data || error.message);
                setShowCookieBanner(true); // Mostra il banner se il consenso non è trovato
            }
        };

        fetchConsent();
    }, [userUUID]);



    // Funzione per salvare il consenso nel backend
    const saveCookieConsent = async (cookiesSettings) => {
        try {
            const response = await axiosInstance.post('/save-consent/', {
                uuid: userUUID, // Usa l'UUID invece dell'email
                cookies_analytics: cookiesSettings.analytics,
                cookies_marketing: cookiesSettings.marketing,
            });
            console.log('Consenso salvato con successo:', response.data);
        } catch (error) {
            console.error('Errore durante il salvataggio del consenso:', error.response?.data || error.message);
        }
    };

    // Gestione del clic su "Accetta Tutti"
    const handleAcceptAll = () => {
        const allCookiesEnabled = {
            necessary: true,
            analytics: true,
            marketing: true,
        };

        saveCookieConsent(allCookiesEnabled);
        setCookiesAccepted(true); // Chiudi il banner
    };

    // Gestione del salvataggio delle impostazioni personalizzate
    const handleSaveSettings = () => {
        saveCookieConsent(cookiesSettings);
        setCookiesAccepted(true); // Chiudi il banner
    };

    // Gestione della personalizzazione
    const handleCustomizeCookies = () => {
        setIsSettingsOpen(true); // Mostra il pannello di personalizzazione
    };

    const theme = useTheme();

    const cloudinaryBaseUrl = process.env.REACT_APP_CLOUDINARY_BASE_URL || "/local_animations/";

    const { RiveComponent, rive } = useRive({
        src: `${cloudinaryBaseUrl}perstudenti.riv`, // Nome file come salvato su Cloudinary o locale
        stateMachines: ['StateMachine1'],
        autoplay: true,
    });
    
    const hoverInInput = useStateMachineInput(rive, 'StateMachine1', 'HoverIn');
    const hoverOutInput = useStateMachineInput(rive, 'StateMachine1', 'HoverOut');

    useEffect(() => {
        const isMobileOrTablet = window.innerWidth < 1024;
        let hoverInInterval;
        let hoverOutTimeout;

        if (isMobileOrTablet) {
            hoverInInterval = setInterval(() => {
                if (hoverInInput) hoverInInput.fire();
                hoverOutTimeout = setTimeout(() => {
                    if (hoverOutInput) hoverOutInput.fire();
                }, 15000);
            }, 5000);
        }

        return () => {
            clearInterval(hoverInInterval);
            clearTimeout(hoverOutTimeout);
        };
    }, [hoverInInput, hoverOutInput]);


    const openPrivacyPolicy = () => {
        setModalContent(<PrivacyPolicy inModal />);
        setIsModalOpen(true);
    };

    const openCookiesPolicy = () => {
        setModalContent(<CookiesPolicy inModal />);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };


    // Funzioni per gestire i popup
    const showSuccessPopup = () => {
        setPopupContent({
            animationType: 'perstudenti_popup_ok', // Nome del file senza estensione
            text: "Grazie per la conferma! Controlla il tuo indirizzo mail e troverai una sorpresa che Bolly ha riservato a Te",
            buttonLabel: "Chiudi",
            onButtonClick: () => setPopupContent(null),
        });
    };

    const showAlreadyRegisteredPopup = () => {
        setPopupContent({
            text: "Ben tornato! La tua mail è già stata confermata. Bolly ti ricontatterà presto.",
            buttonLabel: "OK",
            onButtonClick: () => setPopupContent(null),
        });
    };

    const showErrorPopup = () => {
        setPopupContent({
            text: "Ops! Qualcosa non ha funzionato, aggiorna la pagina.",
            buttonLabel: "Aggiorna",
            onButtonClick: () => window.location.reload(),
        });
    };

    // Modifica la funzione `handleSubmit`
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!termsAccepted) {
            alert('Devi accettare i termini e condizioni per continuare.');
            return;
        }

        try {
            const response = await axiosInstance.post('/create/', { email });
            showSuccessPopup();
        } catch (error) {
            if (error.response?.status === 400) {
                showAlreadyRegisteredPopup();
            } else {
                showErrorPopup();
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MainContainer theme={theme}>

                {/* Mostra il banner dei cookies se non sono stati accettati */}
                {!cookiesAccepted && (
                    <CookieBanner
                        onAcceptAll={handleAcceptAll}
                        onCustomize={handleCustomizeCookies}
                    />
                )}

                {/* Pannello di personalizzazione */}
                {isSettingsOpen && (
                    <div className="cookie-settings">
                        <h3>Personalizza i Cookies</h3>
                        <label>
                            <input
                                type="checkbox"
                                checked={cookiesSettings.analytics}
                                onChange={(e) =>
                                    setCookiesSettings((prev) => ({
                                        ...prev,
                                        analytics: e.target.checked,
                                    }))
                                }
                            />
                            Analytics
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={cookiesSettings.marketing}
                                onChange={(e) =>
                                    setCookiesSettings((prev) => ({
                                        ...prev,
                                        marketing: e.target.checked,
                                    }))
                                }
                            />
                            Marketing
                        </label>
                        <button onClick={handleSaveSettings}>Salva Impostazioni</button>
                    </div>
                )}


                <TopSection>
                    <AnimationWrapper
                        onMouseEnter={() => hoverInInput?.fire()}
                        onMouseLeave={() => hoverOutInput?.fire()}
                    >
                        <RiveComponent />
                    </AnimationWrapper>
                <TextWrapper>
                    <Typography variant="h4" color={theme.palette.text.white}>
                        Semplifica la tua vita universitaria. Vendi, scambia e compra senza stress.
                    </Typography>
                </TextWrapper>
                </TopSection>
                <BottomSection>
                    <FormWrapper>
                        {/* Titolo sopra il form */}
                        <Typography variant="h5" color={theme.palette.text.white} style={{ textAlign: 'center', marginBottom: '16px' }}>
                            Conferma il tuo indirizzo mail per ricevere l'Early Access Pass
                        </Typography>

                        <Form
                            fields={[
                                {
                                    type: 'email',
                                    placeholder: 'Example:bolly@gmail.com',
                                    value: email,
                                    onChange: (e) => setEmail(e.target.value),
                                    required: true,
                                },
                            ]}
                            onSubmit={handleSubmit}
                            isLoading={isLoading}
                            submitLabel="Conferma E-mail"
                            termsAccepted={termsAccepted} // Passa lo stato
                            setTermsAccepted={setTermsAccepted} // Passa il gestore dello stato
                        />
                    </FormWrapper>
                    {popupContent && (
                        <Popup
                            animationType={popupContent.animationType} // Aggiungi qui il tipo di animazione
                            text={popupContent.text}
                            buttonLabel={popupContent.buttonLabel}
                            onButtonClick={popupContent.onButtonClick}
                        />
                    )}
                
                <Footer>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setModalContent(<CookiesPolicy />);
                            setIsModalOpen(true);
                        }}
                    >
                        Cookies Policy
                    </a>
                    |
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setModalContent(<PrivacyPolicy />);
                            setIsModalOpen(true);
                        }}
                    >
                        Privacy Policy
                    </a>
                    |
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setModalContent(<TermsAndConditions />);
                            setIsModalOpen(true);
                        }}
                    >
                        Termini e Condizioni
                    </a>
                    <p>Perstudenti.com - Copyright 2024 ALF</p>
                </Footer>

                </BottomSection>
                <Modal isOpen={isModalOpen} onClose={closeModal} title="Informativa">
                    {modalContent}
                </Modal>
    </MainContainer>
        
    );
};

export default WIP_Page_Perstudenti;
