import React, {useState} from 'react';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import Modal from './Modal'; // Supponendo che Modal sia salvato in Modal.jsx
import TermsAndConditions from './pages/Policies/TermsAndConditions'; // Supponendo che TermsAndConditions sia salvato in TermsAndConditions.jsx
import CircularProgress from '@mui/material/CircularProgress'; // Importa il loader di Material-UI

const LoaderOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Sfondo semi-trasparente */
    z-index: 10;
    border-radius: 8px; /* Segue il design del form */
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: inherit;
    margin: 0 auto;
    padding: 24px;
    border-radius: 8px;
`;

const StyledTextField = styled(TextField)`
    && {
        margin-bottom: 16px;
    }
`;

const Form = ({ fields, onSubmit, isLoading, submitLabel = 'Invia', termsAccepted, setTermsAccepted }) => {
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false); // Stato per la modale

    return (
        <StyledThemeProvider theme={theme}>
            <FormContainer theme={theme} onSubmit={onSubmit} style={{ position: 'relative' }}>
                {/* Overlay del Loader */}
                {isLoading && (
                    <LoaderOverlay>
                        <CircularProgress color="primary" />
                    </LoaderOverlay>
                )}
                {fields.map((field, index) => (
                    <StyledTextField
                        key={index}
                        type={field.type || 'text'}
                        placeholder={field.placeholder}
                        value={field.value}
                        onChange={field.onChange}
                        required={field.required || false}
                        fullWidth
                        variant="outlined"
                        disabled={isLoading}
                    />
                ))}

                {/* Checkbox per Termini e Condizioni */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            required
                            color="primary"
                            disabled={isLoading} // Disabilita il checkbox durante il caricamento
                        />
                    }
                    label={
                        <span>
                            Accetto i{' '}
                            <span
                                style={{
                                    color: theme.palette.primary.main,
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}
                                onClick={() => !isLoading && setIsModalOpen(true)} // Apri la modale solo se non sta caricando
                            >
                                Termini e Condizioni
                            </span>
                        </span>
                    }
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    fullWidth
                >
                    {isLoading ? 'Invio...' : submitLabel}
                </Button>

                {/* Modale per Termini e Condizioni */}
                <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)} // Chiudi la modale
                    title="Termini e Condizioni"
                >
                    <TermsAndConditions inModal />
                </Modal>
            </FormContainer>
        </StyledThemeProvider>
    );
};

export default Form;
