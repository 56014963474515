import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const ModalContainer = styled.div`
    position: fixed;
    background-color: ${(props) => props.theme.palette.background.default};
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    /* Modal centrata per desktop e tablet */
    @media (min-width: 768px) {
        top: 50%;
        left: 50%;
        transform: ${(props) => (props.isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)')};
        width: 80%;
        max-width: 700px;
        max-height: 80%;
    }

    @media (min-width: 1024px) {
        width: 70%;
        max-width: 1000px;
        max-height: 70%;
    }

    /* Modal che scorre dal basso per mobile */
    @media (max-width: 767px) {
        bottom: 0;
        left: 0;
        transform: ${(props) => (props.isOpen ? 'translateY(0)' : 'translateY(100%)')};
        width: 100%;
        max-height: 70%;
        border-radius: 16px 16px 0 0;
    }
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.h4`
    margin: 0;
    font-size: 18px;
    color: ${(props) => props.theme.palette.text.primary};

    @media (min-width: 768px) {
        font-size: 22px;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
`;

const CloseButton = styled.button`
    all: unset;
    cursor: pointer;
    font-size: 18px;
    color: ${(props) => props.theme.palette.text.primary};

    @media (min-width: 768px) {
        font-size: 22px;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
`;

const ModalContent = styled.div`
    font-size: 14px;
    line-height: 1.6;
    color: ${(props) => props.theme.palette.text.secondary};

    @media (min-width: 768px) {
        font-size: 16px;
    }

    @media (min-width: 1024px) {
        font-size: 18px;
    }
`;

const Modal = ({ isOpen, onClose, title, children }) => {

    if (!isOpen) return null; // Non renderizzare nulla se isOpen è false

    return (
        <>
            {isOpen && <Overlay onClick={onClose} />}
            <ModalContainer isOpen={isOpen}>
                <ModalHeader>
                    <Title>{title}</Title>
                    <CloseButton onClick={onClose}>
                        &times;
                    </CloseButton>
                </ModalHeader>
                <ModalContent>{children}</ModalContent>
            </ModalContainer>
        </>
    );
};

export default Modal;
